import React, { Component } from "react";

export default class Front extends Component {
    render() {
        return (
            <div className = "front-page"> 
                <div className = "line1">
                    {/* <hr width="1" size="50" /> */}
                </div>
                <div className = "big-name">
                    <h1> Zora Watters </h1>
                </div>
                <div className="line1"></div>
            </div>
        )
    }
}